const animated = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add("active");
      observer.unobserve(entry.target);
    }
  });
});

export default {
  bind(entry) {
    entry.classList.add("unactive");
    animated.observe(entry);
  },
  disconnect() {
    animated.disconnect();
  },
};
