<template>
    <div class="flex-col container">
        <div class="flex-row" style="column-gap: 10%;">
            <div class="flex1 flex-col-hcenter" style="row-gap: 15px;">
                <LogoSvg />
                <div class="flex-row-hcenter slogan-border">
                    <p class="slogan-text">SLOGAN HERE</p>
                </div>
                <div class="flex-row-hcenter">
                    <p class="read-more-text">READ MORE ABOUT US</p>
                </div>

            </div>
            <div class="flex1">

            </div>
            <div class="flex1 flex-col-hcenter" style="row-gap: 15px;">
                <div class="flex-row-hcenter slogan-border">
                    <p class="slogan-text">NAVIGATION</p>
                </div>
                <p class="row-text" @click="$router.push('/')">HOME</p>
                <p class="row-text" @click="$router.push({ path: '/list/1', query: { page: 1, page_size: 16 } })">
                    HISTORY</p>
                <p class="row-text" @click="$router.push({ path: '/list/2', query: { page: 1, page_size: 16 } })">
                    GALLERY</p>
                <p class="row-text" @click="$router.push({ path: '/list/3', query: { page: 1, page_size: 16 } })">
                    SCIENCE&TECH</p>
                <p class="row-text" @click="$router.push({ path: '/list/4', query: { page: 1, page_size: 16 } })">
                    CONTEMPLATION</p>
                <p class="row-text" @click="goAboutUs">ABOUT US
                </p>
                <p class="row-text" @click="$router.push('/contactus')">CONTACT US</p>
            </div>
            <div class="flex1 flex-col-hcenter" style="row-gap: 15px;">
                <div class="flex-row-hcenter slogan-border">
                    <p class="slogan-text">SUPPORT</p>
                </div>
                <p class="row-text">FAQS</p>
                <p class="row-text" @click="goNews">NEWS</p>
                <p class="row-text" @click="goWorldMap">GLOBAL / ALL LOCATIONS</p>
                <div class="flex-row-hcenter slogan-border">
                    <p class="slogan-text">DOWNLOADS</p>
                </div>
                <p class="row-text" @click="goDownload">WAC MUSEUM BROCHURE</p>
            </div>
        </div>
        <el-divider />
        <div class="flex-row-between">
            <div class="flex-row">
                <el-button type="text" size="small">
                    <FacebookSvg />
                </el-button>
                <el-button type="text" size="small">
                    <InsSvg />
                </el-button>
                <el-button type="text" size="small">
                    <PlaySvg />
                </el-button>
                <el-button type="text" size="small">
                    <CameraSvg />
                </el-button>
            </div>
            <p class="row-text">Copyright 2024 WAC Lighting</p>
        </div>
    </div>
</template>

<script>
import LogoSvg from '@/assets/svg/logo-footer.svg';
import FacebookSvg from '@/assets/svg/facebook.svg';
import InsSvg from '@/assets/svg/ins.svg';
import PlaySvg from '@/assets/svg/play.svg';
import CameraSvg from '@/assets/svg/camera.svg';
export default {
    components: {
        LogoSvg,
        FacebookSvg,
        InsSvg,
        PlaySvg,
        CameraSvg,
    },
    data() {
        return {
            worldMapHeight: 3 * window.innerHeight,
        }
    },
    methods: {
        goAboutUs() {
            window.open("https://www.waclighting.net/about#about", "_blank");
        },
        goNews() {
            window.open("https://www.waclighting.net/news/1", "_blank");
        },
        goWorldMap() {
            this.$router.push({ path: "/", query: { height: this.worldMapHeight } })
        },
        goDownload() {
            window.open("https://www.waclighting.net/download/5", "_blank");
        },
    }
}
</script>

<style scoped>
.container {
    background-color: #f5f5f5;
    padding: 3%;
}

.slogan-border {
    width: 100%;
    border: 1px solid var(--description-color);
    border-radius: 5px;
}

.slogan-text {
    margin: 10px;
    font-size: 20px;
    font-weight: bold;
}

.read-more-text {
    font-size: 24px;
    font-weight: bold;
}

.row-text {
    cursor: pointer;
    font-size: 18px;
    transition: color 0.25s ease-in;
}

.row-text:hover {
    color: var(--theme-color);
}

/* 手机 */
@media screen and (max-width:767px) {
    .slogan-text {
        font-size: 10px;
    }

    .read-more-text {
        font-size: 10px;
    }

    .row-text {
        font-size: 8px;
    }
}

/* 平板 */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .slogan-text {
        font-size: 10px;
    }

    .read-more-text {
        font-size: 10px;
    }

    .row-text {
        font-size: 8px;
    }
}

/* 小屏幕 */
@media screen and (min-width: 992px) and (max-width: 1279px) {
    .slogan-text {
        font-size: 14px;
    }

    .read-more-text {
        font-size: 14px;
    }

    .row-text {
        font-size: 12px;
    }
}

/* 17寸显示器 1280*700以上 */
@media screen and (min-width: 1280px) and (max-width: 1365px) {
    .slogan-text {
        font-size: 16px;
    }

    .read-more-text {
        font-size: 18px;
    }

    .row-text {
        font-size: 14px;
    }
}

/* 18.5寸显示器 1366*768以上 */
@media screen and (min-width: 1366px) and (max-width: 1599px) {
    .slogan-text {
        font-size: 18px;
    }

    .read-more-text {
        font-size: 20px;
    }

    .row-text {
        font-size: 16px;
    }
}

/* 20寸显示器 1600*900以上*/
@media screen and (min-width: 1600px) {
    .slogan-text {
        font-size: 20px;
    }

    .read-more-text {
        font-size: 22px;
    }

    .row-text {
        font-size: 18px;
    }
}
</style>