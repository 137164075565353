import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menuHeight: 0,
    scrollY: 0,
    currPath: "/",
    cachedData: {},
  },
  getters: {},
  mutations: {
    setMenuHeight(state, height) {
      state.menuHeight = height;
    },
    setScrollY(state, scrollY) {
      state.scrollY = scrollY;
    },
    // main.js监听路由路径改变
    setCurrPath(state, path) {
      state.currPath = path;
    },
    setCachedData(state, { cacheKey, data }) {
      state.cachedData[cacheKey] = data;
    },
  },
  actions: {
    // main.js监听路由路径改变
    setCurrPath({ commit }, path) {
      commit("setCurrPath", path);
    },
  },
});
