<template>
    <div class="nav-parent" :class="{ 'nav-parent-active': scrollY > 0 || isSearchView }">
        <div class="logo-area" @click="goHome">
            <div class="logo-icon">
                <LogoUnactiveSvg class="logo-unactive" :style="logoStyle" />
                <LogoActiveSvg class="logo-active" :style="logoStyle" />
            </div>
        </div>
        <div style="flex: 6;">
            <el-menu ref="menu" class="flex-row-hcenter" mode="horizontal" background-color="transparent"
                active-text-color="#ffc430" :default-active="currPath" :router="true">
                <el-menu-item style="font-size: 16px;" v-for="item in menus" :key="item.route.path"
                    :index="item.route.path" :route="item.route">
                    {{ item.text }}
                </el-menu-item>
            </el-menu>
        </div>
        <div class="flex1 flex-row-end-center" style="padding-right: 2%;">
            <SearchSvg class="search-svg" @click="goSearch" />
        </div>
    </div>
</template>
<script>
// https://blog.csdn.net/Y2960018173/article/details/134806261
// https://www.jb51.net/javascript/285171abh.htm
// https://blog.csdn.net/bu_xiang_tutou/article/details/129678103
// https://www.cnblogs.com/520BigBear/p/12889412.html
// https://www.jb51.net/article/281045.htm
import { mapState, mapMutations } from 'vuex';
import LogoUnactiveSvg from '@/assets/svg/logo-unactive.svg';
import LogoActiveSvg from '@/assets/svg/logo-active.svg';
import SearchSvg from '@/assets/svg/search.svg';
export default {
    components: {
        LogoUnactiveSvg,
        LogoActiveSvg,
        SearchSvg,
    },
    computed: {
        ...mapState(['menuHeight', 'scrollY', 'currPath']),
        logoStyle() {
            return {
                height: `calc(${this.menuHeight}px - 10px)`
            }
        },
        isSearchView() {
            return (this.currPath === "/search" || this.currPath.includes("/list/"))
        },
    },
    data() {
        return {
            // todo 动态获取
            menus: [
                { text: "HISTORY", route: { path: "/list/1", query: { page: 1, page_size: 16 } } },
                { text: "GALLERY", route: { path: "/list/2", query: { page: 1, page_size: 16 } } },
                { text: "SCIENCE & TECH", route: { path: "/list/3", query: { page: 1, page_size: 16 } } },
                { text: "CONTEMPLATION", route: { path: "/list/4", query: { page: 1, page_size: 16 } } },
            ],
        }
    },
    methods: {
        ...mapMutations(['setMenuHeight']),
        updateMenuHeight() {
            this.setMenuHeight(this.$refs.menu.$el.offsetHeight);
        },
        goHome() {
            this.$router.push("/");
        },
        goSearch() {
            this.$router.push({
                path: "/search", query: { page: 1, page_size: 16, }
            });
        },
    },
    mounted() {
        this.updateMenuHeight();
        // window.addEventListener('resize', this.updateMenuHeight);
    },
    beforeDestroy() {
        // window.removeEventListener('resize', this.updateMenuHeight);
    }
}
</script>
<style scoped>
.nav-parent {
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    position: fixed;
    background-color: transparent;
    box-shadow: none;

    -webkit-transition: background-color 0.75s ease, box-shadow 0.75s ease;
    -moz-transition: background-color 0.75s ease, box-shadow 0.75s ease;
    -ms-transition: background-color 0.75s ease, box-shadow 0.75s ease;
    -o-transition: background-color 0.75s ease, box-shadow 0.75s ease;
    transition: background-color 0.75s ease, box-shadow 0.75s ease;

    display: flex;
}

.nav-parent:hover {
    background-color: white;
    box-shadow: 0px 1px 3px var(--description-color);
}

.nav-parent-active {
    background-color: white;
    box-shadow: 0px 1px 3px var(--description-color);
}

.logo-area {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2%;
}

.logo-icon {
    position: relative;
}

.logo-unactive {
    cursor: pointer;
    opacity: 1;

    -webkit-transition: opacity 0.75s ease;
    -moz-transition: opacity 0.75s ease;
    -ms-transition: opacity 0.75s ease;
    -o-transition: opacity 0.75s ease;
    transition: opacity 0.75s ease;
}

.nav-parent:hover .logo-unactive,
.nav-parent-active .logo-unactive {
    opacity: 0;
}

.logo-active {
    cursor: pointer;

    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    
    opacity: 0;

    -webkit-transition: opacity 0.75s ease;
    -moz-transition: opacity 0.75s ease;
    -ms-transition: opacity 0.75s ease;
    -o-transition: opacity 0.75s ease;
    transition: opacity 0.75s ease;
}

.nav-parent:hover .logo-active,
.nav-parent-active .logo-active {
    opacity: 1;
}

.el-menu-item {
    padding-left: 3%;
    padding-right: 3%;
    text-align: center;
    color: white !important;

    -webkit-transition: opacity 0.75s ease;
    -moz-transition: opacity 0.75s ease;
    -ms-transition: opacity 0.75s ease;
    -o-transition: opacity 0.75s ease;
    transition: opacity 0.75s ease;
}

.el-menu--horizontal {
    /* 去掉底部的白线 */
    border-bottom: none !important;
}

.nav-parent:hover .el-menu .el-menu-item,
.nav-parent-active .el-menu .el-menu-item {
    color: var(--title-color) !important;
}

.search-svg {
    cursor: pointer;
    height: 24px;
}

.search-svg path {
    -webkit-transition: stroke 0.75s ease;
    -moz-transition: stroke 0.75s ease;
    -ms-transition: stroke 0.75s ease;
    -o-transition: stroke 0.75s ease;
    transition: stroke 0.75s ease;
}

.nav-parent:hover .search-svg path,
.nav-parent-active .search-svg path {
    stroke: var(--title-color);
}
</style>