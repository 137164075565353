import Vue from "vue";
import App from "./App.vue";

// element ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
// import "@/components/element.js";
// 全局样式
import "./assets/styles/main.css";

// vue-router
import router from "@/router";

// 滚动监听
import observer from "./observe/observer";
Vue.directive("observe", observer);
Vue.prototype.$observer = observer;

// vuex
import store from "./store/vuex";
// 监听路由路径改变，如：切换顶部导航栏、点击搜索键
router.beforeEach((to, from, next) => {
  store.dispatch("setCurrPath", to.path);
  next();
});

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router: router,
  store,
}).$mount("#app");
