<template>
  <div id="app">
    <TopNav ref="topnav" :scrollY="scrollY" />
    <el-container>
      <el-main style="padding: 0px;">
        <keep-alive :include="keepAlive">
          <router-view />
        </keep-alive>
      </el-main>
      <el-footer style="padding: 0px;">
        <FooterNav />
      </el-footer>
    </el-container>
    <el-button class="go-top" :class="{ 'go-top-active': scrollY >= 100 }" size="small" circle @click="goTop">
      <ArrowTopSvg />
    </el-button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import TopNav from './components/TopNav.vue';
import FooterNav from './components/FooterNav.vue';
import ArrowTopSvg from '@/assets/svg/arrow-top-small.svg';
export default {
  name: 'App',
  components: {
    TopNav,
    FooterNav,
    ArrowTopSvg,
  },
  data() {
    return {
      scrollY: 0,
      keepAlive: ['HomeView', 'ContactUsView'],
    }
  },
  methods: {
    ...mapMutations(['setScrollY']),
    updateScrollY() {
      this.scrollY = window.scrollY;
      this.setScrollY(window.scrollY);
    },
    goTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
  mounted() {
    // 监听滚动值
    window.addEventListener('scroll', this.updateScrollY);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScrollY);
    this.$observer.disconnect();
  }
}
</script>

<style scoped>
.go-top {
  position: fixed;
  right: 2%;
  bottom: 2%;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.75s ease;
}

.go-top:active,
.go-top:hover,
.go-top:focus {
  border-color: #DCDFE6;
  background-color: white;
}

.go-top:active path,
.go-top:hover path,
.go-top:hover path {
  stroke: var(--theme-color);
}

.go-top-active {
  opacity: 1;
}
</style>
