import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/view/HomeView.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/view/SearchView.vue"),
  },
  {
    path: "/list/:category",
    name: "list",
    component: () => import("@/view/SearchView.vue"),
  },
  {
    path: "/detail/:id",
    name: "detail",
    component: () => import("@/view/DetailView.vue"),
  },
  {
    path: "/contactus",
    name: "contactus",
    component: () => import("@/view/ContactUsView.vue"),
  },
];

const router = new VueRouter({
  base: "/museum",
  // mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.query.height) {
      return {
        x: 0,
        y: parseInt(to.query.height),
        behavior: "smooth",
      };
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

// router.beforeEach((to, from, next) => {
//   // // 执行一些逻辑
//   // console.log('Navigating from', from.path, 'to', to.path);

//   // // 模拟权限验证
//   // const isAuthenticated = true; // 假设用户已登录

//   // if (to.name === 'About' && !isAuthenticated) {
//   //   alert('You are not authenticated!');
//   //   next('/'); // 重定向到首页
//   // } else {
//   //   next(); // 继续导航
//   // }
//   if (to.path === from.path) {
//     next(false);
//   } else {
//     next();
//   }
// });

export default router;
